import React, { FunctionComponent } from "react";
import Image from "../../../Image";

type MonitoringTabProps = {
  icon: any;
};
const MonitoringTab: FunctionComponent<MonitoringTabProps> = ({
  icon,
  children,
}) => {
  return (
    <div className="tab-row">
      <div>
        <Image fluid={icon.childImageSharp.fluid} className="tab-icon" />
      </div>
      <div className="tab-body">{children}</div>
      <div className="tab-indicator">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          viewBox="0 0 44 44"
        >
          <g fill="none" fillRule="evenodd" className="arrow">
            <circle cx="22" cy="22" r="21.5" />
            <polyline strokeWidth="3" points="11 16 22 28 33 16" />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default MonitoringTab;
