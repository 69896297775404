import React, { FunctionComponent } from "react";
import Headline from "../../Headline";
import Tabcordion from "../../Tabcordion/Tabcordion";
import Translation from "../../Translation";
import ManagingHepB from "../Videos/ManagingHepB";
import {
  Key1Panel,
  Key1Tab,
  Key2Panel,
  Key2Tab,
  Key3Panel,
  Key3Tab,
  Key4Panel,
  Key4Tab,
  Key5Panel,
  Key5Tab,
} from "./Keys";
const FiveKeys: FunctionComponent = () => {
  return (
    <div id="managing-hep-b" className="chapter">
      <Headline>
        <h2>
          <Translation id="five-keys" />
        </h2>
      </Headline>

      <div className="mt-12 px-4 laptop:px-8 flex flex-col laptop:flex-row">
        <div className="w-full laptop:w-1/2 mr-8">
          <h3 className="text-green">
            <Translation id="taking-care-of-yourself" />
          </h3>
          <p>
            <Translation id="important-to-talk-to-doctor-treatment" />
          </p>
        </div>
        <div className="hidden laptop:block laptop:w-1/2">
          <ManagingHepB />
        </div>
      </div>

      <div className="pt-8 px-4">
        <p className="font-bold laptop:font-normal">
          <Translation id="five-things-you-can-do" />
        </p>

        <Tabcordion
          tabs={[
            <Key1Tab />,
            <Key2Tab />,
            <Key3Tab />,
            <Key4Tab />,
            <Key5Tab />,
          ]}
          panels={[
            <Key1Panel />,
            <Key2Panel />,
            <Key3Panel />,
            <Key4Panel />,
            <Key5Panel />,
          ]}
          initialPanel={-1}
          expandBreakpoint="laptop"
          className="tabcordion--keys my-16"
          collapsable
        />
      </div>
    </div>
  );
};

export default FiveKeys;
