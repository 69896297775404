import React, { FunctionComponent } from "react";
import Image from "../../../Image";
import Translation, { useTranslation } from "../../../Translation";

type AltPanelProps = {
  icon: any;
  caution: any;
};

const AltPanel: FunctionComponent<AltPanelProps> = ({ icon, caution }) => {
  const { t } = useTranslation();
  return (
    <div className="pr-4 laptop:px-4">
      <div className="flex w-full">
        <div className="hidden laptop:block laptop:w-1/4">
          <Image
            fluid={icon.childImageSharp.fluid}
            alt={t("alt-test-alt")}
            className="mt-4"
          />
        </div>
        <div className="w-full laptop:w-3/4">
          <p>
            <Translation id="alt-is-enzyme" />
          </p>
          <p>
            <Translation id="normal-alt-levels" />
          </p>
          <p>
            <Translation id="if-alt-high" />
          </p>
        </div>
      </div>
      <div className="flex w-full">
        <div className="w-1/4 mx-auto">
          <Image
            fluid={caution.childImageSharp.fluid}
            alt={t("warning-symbol")}
            className="mt-4 mr-4"
          />
        </div>
        <div className="w-3/4">
          <p className="text-red font-bold">
            <Translation id="viral-load-and-alt-can-change" />
          </p>
          <p>
            <Translation id="important-to-test-regularly" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default AltPanel;
