import React, { FunctionComponent } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Translation from "../../../Translation";
import MonitoringTab from "./MonitoringTab";

const CancerTab: FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      liverCancer: file(base: { eq: "liver-cancer-icon.png" }) {
        ...SmallImage
      }
    }
  `);

  return (
    <MonitoringTab icon={data.liverCancer}>
      <Translation id="liver-cancer-screening" />
    </MonitoringTab>
  );
};

export default CancerTab;
