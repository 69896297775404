import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Translation, { useTranslation } from "../../../Translation";
import MythPanel from "./MythPanel";

const Myth3Panel: FunctionComponent = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      family: file(base: { eq: "family-alt.png" }) {
        ...SmallImage
      }
    }
  `);
  return (
    <MythPanel icon={data.family} alt={t("myth-spread-alt")}>
      <Translation id="not-spread-by-casual" />
    </MythPanel>
  );
};

export default Myth3Panel;
