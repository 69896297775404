import React, { FunctionComponent } from "react";
import Translation from "../../../Translation";
import MythsTab from "./MythsTab";

const Myth6Tab: FunctionComponent = () => {
  return (
    <MythsTab>
      <Translation id="myth-expensive" />
    </MythsTab>
  );
};

export default Myth6Tab;
