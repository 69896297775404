import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Translation, { useTranslation } from "../../../Translation";
import MythPanel from "./MythPanel";

const Myth4Panel: FunctionComponent = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      injection: file(base: { eq: "injection.png" }) {
        ...SmallImage
      }
    }
  `);
  return (
    <MythPanel icon={data.injection} alt={t("myth-vaccine-alt")}>
      <Translation id="vaccine-only-for-uninfected" />
    </MythPanel>
  );
};

export default Myth4Panel;
