import React, { FunctionComponent } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Translation from "../../../Translation";
import MonitoringTab from "./MonitoringTab";

const HbvDnaTab: FunctionComponent = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      virus: file(base: { eq: "virus.png" }) {
        ...SmallImage
      }
    }
  `);
  return (
    <MonitoringTab icon={data.virus}>
      <Translation id="hbv-dna-test" />
    </MonitoringTab>
  );
};

export default HbvDnaTab;
