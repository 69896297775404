import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Translation from "../../../Translation";
import KeysTab from "./KeysTab";

const Key2Tab: FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      inactiveIcon: file(base: { eq: "two-inactive.png" }) {
        ...SmallImage
      }
      activeIcon: file(base: { eq: "two-active.png" }) {
        ...SmallImage
      }
    }
  `);

  return (
    <KeysTab {...data}>
      <Translation id="get-tested-regularly" />
    </KeysTab>
  );
};

export default Key2Tab;
