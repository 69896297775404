import React, { FunctionComponent } from "react";
import Headline from "../../Headline";
import Tabcordion from "../../Tabcordion/Tabcordion";
import Translation from "../../Translation";
import WhatShouldIKnow from "../Videos/WhatShouldIKnow";
import {
  Myth1Panel,
  Myth1Tab,
  Myth2Panel,
  Myth2Tab,
  Myth3Panel,
  Myth3Tab,
  Myth4Panel,
  Myth4Tab,
  Myth5Panel,
  Myth5Tab,
  Myth6Panel,
  Myth6Tab,
  Myth7Panel,
  Myth7Tab,
} from "./Myths";

const LearnTheTruth: FunctionComponent = () => {
  return (
    <div id="hep-b-facts" className="chapter">
      <Headline>
        <h2>
          <Translation id="learn-the-truth" />
        </h2>
      </Headline>

      <div className="py-8 px-4 laptop:px-8">
        <div className="flex flex-col laptop:flex-row">
          <div className="w-full laptop:w-1/2 laptop:pr-8">
            <p>
              <Translation id="affects-each-person-differently" />
            </p>
            <p>
              <Translation id="here-are-some-things" />
            </p>
          </div>
          <div className="w-full laptop:w-1/2">
            <WhatShouldIKnow />
          </div>
        </div>
        <p className="font-bold laptop:text-lg laptop:font-normal py-4">
          <Translation id="seven-myths" />
        </p>
        <div className="shadow-myths">
          <Tabcordion
            tabs={[
              <Myth1Tab />,
              <Myth2Tab />,
              <Myth3Tab />,
              <Myth4Tab />,
              <Myth5Tab />,
              <Myth6Tab />,
              <Myth7Tab />,
            ]}
            panels={[
              <Myth1Panel />,
              <Myth2Panel />,
              <Myth3Panel />,
              <Myth4Panel />,
              <Myth5Panel />,
              <Myth6Panel />,
              <Myth7Panel />,
            ]}
            initialPanel={-1}
            expandBreakpoint="never"
            className="tabcordion--myths my-16"
            collapsable
          />
        </div>
      </div>
    </div>
  );
};

export default LearnTheTruth;
