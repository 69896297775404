import React, { FunctionComponent } from "react";
import Translation from "../../../Translation";
import Image from "../../../Image";

type MythPanelProps = {
  icon: any;
  alt?: string;
};
const MythPanel: FunctionComponent<MythPanelProps> = ({
  icon,
  alt,
  children,
}) => {
  return (
    <div className="mx-4">
      <div className="flex flex-col laptop:flex-row items-center laptop:items-start">
        <div className="w-full laptop:w-1/12">
          <div className="flex items-center">
            <Image fluid={icon.childImageSharp.fluid} alt={alt} width={48} />
            <strong className="text-green text-lg uppercase pl-4 laptop:hidden">
              <Translation id="truth" />
            </strong>
          </div>
        </div>
        <div className="laptop:w-11/12">
          <div>
            <strong className="hidden laptop:block text-green text-lg uppercase">
              <Translation id="truth" />
            </strong>
          </div>
          <p className="text-green">{children}</p>
        </div>
      </div>
    </div>
  );
};

export default MythPanel;
