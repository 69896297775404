import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Translation from "../../../Translation";
import Video from "../../../Video";
import Image from "../../../Image";

const Key4Panel: FunctionComponent = () => {
  return (
    <div className="">
      <div className="hidden laptop:block text-center font-bold text-2xl pt-4 pb-12">
        <Translation id="talk-openly-with-doctor" />
      </div>
      <p>
        <Translation id="the-more-information-the-better" />
      </p>
      <p className="text-green font-bold">
        <Translation id="before-visits" />
      </p>
      <ul className="bullet">
        <li>
          <Translation id="ask-family-to-come" />
        </li>
        <li>
          <Translation id="bring-notebook" />
        </li>
      </ul>
      <p className="text-green font-bold">
        <Translation id="during-visits" />
      </p>
      <ul className="bullet">
        <li>
          <Translation id="take-notes" />
        </li>
        <li>
          <Translation id="be-honest-and-ask" />
        </li>
      </ul>
    </div>
  );
};

export default Key4Panel;
