import React, { FunctionComponent } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Image from "../../../Image";

type KeysTabProps = {
  inactiveIcon: any;
  activeIcon: any;
};
const KeysTab: FunctionComponent<KeysTabProps> = ({
  inactiveIcon,
  activeIcon,
  children,
}) => {
  const data = useStaticQuery(graphql`
    query {
      arrow: file(base: { eq: "arrow-down.svg" }) {
        publicURL
      }
    }
  `);
  return (
    <div className="tab-row">
      <div className="tab-icon">
        <Image
          fluid={inactiveIcon.childImageSharp.fluid}
          className="key inactive"
        />
        <Image
          fluid={activeIcon.childImageSharp.fluid}
          className="key active"
        />
      </div>
      <div className="tab-body">{children}</div>
      <img src={data.arrow.publicURL} className="tab-indicator" />
    </div>
  );
};

export default KeysTab;
