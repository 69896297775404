import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Translation, { useTranslation } from "../../../Translation";
import Image from "../../../Image";

const Key4Panel: FunctionComponent = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      caution: file(base: { eq: "caution.png" }) {
        ...SmallImage
      }
    }
  `);
  return (
    <div className="">
      <div className="hidden laptop:block text-center font-bold text-2xl pt-4 pb-12">
        <Translation id="take-your-medicine" />
      </div>
      <h3 className="text-green">
        <Translation id="always-take-your-medicine" />
      </h3>
      <p>
        <Translation id="when-tests-show-high-load-antiviral" />
      </p>
      <div className="flex w-full">
        <div className="w-1/5 mx-auto">
          <Image
            fluid={data.caution.childImageSharp.fluid}
            alt={t("warning-symbol")}
            className="mt-4 mr-4"
          />
        </div>
        <div className="w-4/5 laptop:self-center">
          <p className="text-red font-bold">
            <Translation id="dangerous-to-stop" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Key4Panel;
