import React, { FunctionComponent } from "react";
import Translation from "../../../Translation";
import MythsTab from "./MythsTab";

const Myth5Tab: FunctionComponent = () => {
  return (
    <MythsTab>
      <Translation id="myth-no-treatment" />
    </MythsTab>
  );
};

export default Myth5Tab;
