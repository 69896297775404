import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Translation from "../../Translation";
import Article from "../../Article";
import OngoingMonitoring from "./OngoingMonitoring";
import FiveKeys from "./FiveKeys";
import LearnTheTruth from "./LearnTheTruth";

const LivingWithHepB = () => {
  // const data = useStaticQuery(
  //   graphql`
  //     query {
  //     }
  //   `
  // )

  return (
    <section id="living-with-hep-b" className="main-section">
      <Article>
        <OngoingMonitoring />
        <FiveKeys />
        <LearnTheTruth />
      </Article>
    </section>
  );
};
export default LivingWithHepB;
