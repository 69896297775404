import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Translation, { useTranslation } from "../../../Translation";
import Image from "../../../Image";
import RegularCheckups from "../../Videos/RegularCheckups";

const Key2Panel: FunctionComponent = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      caution: file(base: { eq: "caution.png" }) {
        ...SmallImage
      }
    }
  `);
  return (
    <div className="">
      <div className="hidden laptop:block text-center font-bold text-2xl pt-4 pb-12">
        <Translation id="get-tested-regularly" />
      </div>
      <div className="flex flex-col laptop:flex-row font-normal">
        <div className="w-full laptop:w-1/2 laptop:pr-8">
          <p>
            <Translation id="because-amount-varies-see-doctor-regularly" />
          </p>
          <p>
            <Translation id="routine-monitoring" />
          </p>
          <p>
            <Translation id="skipping-appointments-may-affect" />
          </p>
        </div>
        <div className="w-full laptop:w-1/2">
          <RegularCheckups />
        </div>
      </div>
      <div className="flex w-full">
        <div className="w-1/5 mx-auto">
          <Image
            fluid={data.caution.childImageSharp.fluid}
            alt={t("remind-doctor-alt")}
            className="mt-4 mr-4"
          />
        </div>
        <div className="w-4/5 laptop:self-center">
          <p className="text-red font-bold">
            <Translation id="remind-doctor" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Key2Panel;
