import React, { FunctionComponent } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Translation from "../../../Translation";
import KeysTab from "./KeysTab";

const Key1Tab: FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      inactiveIcon: file(base: { eq: "one-inactive.png" }) {
        ...SmallImage
      }
      activeIcon: file(base: { eq: "one-active.png" }) {
        ...SmallImage
      }
    }
  `);

  return (
    <KeysTab {...data}>
      <Translation id="take-care-of-body" />
    </KeysTab>
  );
};

export default Key1Tab;
