import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Translation, { useTranslation } from "../../../Translation";
import MythPanel from "./MythPanel";

const Myth5Panel: FunctionComponent = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      prescription: file(base: { eq: "prescription.png" }) {
        ...SmallImage
      }
    }
  `);
  return (
    <MythPanel icon={data.prescription} alt={t("myth-medicine-alt")}>
      <Translation id="effective-medicines" />
    </MythPanel>
  );
};

export default Myth5Panel;
