import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Headline from "../../Headline";
import Tabcordion from "../../Tabcordion/Tabcordion";
import Translation from "../../Translation";
import AltPanel from "./Monitoring/AltPanel";
import AltTab from "./Monitoring/AltTab";
import CancerPanel from "./Monitoring/CancerPanel";
import CancerTab from "./Monitoring/CancerTab";
import HbvDnaPanel from "./Monitoring/HbvDnaPanel";
import HbvDnaTab from "./Monitoring/HbvDnaTab";

const OngoingMonitoring: FunctionComponent = () => {
  const data = useStaticQuery(
    graphql`
      query {
        virus: file(base: { eq: "virus.png" }) {
          ...SmallImage
        }
        infectedLiver: file(base: { eq: "infected-liver.png" }) {
          ...SmallImage
        }
        liverCancer: file(base: { eq: "liver-cancer-icon.png" }) {
          ...SmallImage
        }
        caution: file(base: { eq: "caution.png" }) {
          ...SmallImage
        }
      }
    `
  );

  return (
    <div id="ongoing-monitoring" className="chapter">
      <Headline>
        <h1>
          <Translation id="ongoing-monitoring" />
        </h1>
      </Headline>
      <div className="px-4 py-8">
        <h2>
          <Translation id="living-with-hepb" />
        </h2>

        <p className="pb-4">
          <Translation id="having-chb-means" />
        </p>

        <p className="font-bold text-green">
          <Translation id="getting-tested-regularly" />
        </p>
        <p className="font-bold">
          <Translation id="some-tests" />
        </p>

        <Tabcordion
          tabs={[<HbvDnaTab />, <AltTab />, <CancerTab />]}
          panels={[
            <HbvDnaPanel icon={data.virus} caution={data.caution} />,
            <AltPanel icon={data.infectedLiver} caution={data.caution} />,
            <CancerPanel icon={data.liverCancer} caution={data.caution} />,
          ]}
          initialPanel={-1}
          expandBreakpoint="laptop"
          className="tabcordion--monitoring my-8"
          collapsable
        />
      </div>
    </div>
  );
};

export default OngoingMonitoring;
