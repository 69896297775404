import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Translation, { useTranslation } from "../../../Translation";
import MythPanel from "./MythPanel";

const Myth2Panel: FunctionComponent = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      liver: file(base: { eq: "liver-plus.png" }) {
        ...SmallImage
      }
    }
  `);
  return (
    <MythPanel icon={data.liver} alt={t("myth-carrier-alt")}>
      <Translation id="no-such-thing-as-carrier" />
    </MythPanel>
  );
};

export default Myth2Panel;
