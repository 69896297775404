import React, { FunctionComponent } from "react";
import Translation, { useTranslation } from "../../../Translation";
import IconRow from "../../../IconRow";
import { graphql, useStaticQuery } from "gatsby";

const Key3Panel: FunctionComponent = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      dontShare: file(base: { eq: "dont-share.png" }) {
        ...SmallImage
      }
      dontShareNeedles: file(base: { eq: "dont-share-needles.png" }) {
        ...SmallImage
      }
      avoidDonating: file(base: { eq: "avoid-donating.png" }) {
        ...SmallImage
      }
      useCondoms: file(base: { eq: "use-condoms.png" }) {
        ...SmallImage
      }
      coverCuts: file(base: { eq: "cover-cuts.png" }) {
        ...SmallImage
      }
      clean: file(base: { eq: "clean.png" }) {
        ...SmallImage
      }
      family: file(base: { eq: "family.png" }) {
        ...SmallImage
      }
    }
  `);

  return (
    <div>
      <div className="hidden laptop:block text-center font-bold text-2xl pt-4 pb-12">
        <Translation id="prevent-spreading" />
      </div>
      <h3 className="text-green">
        <Translation id="protect-yourself-and-others" />
      </h3>
      <div className="flex flex-col laptop:flex-row">
        <div className="w-full laptop:w-1/2 laptop:pr-8">
          <IconRow image={data.dontShare} alt={t("never-share-blood-alt")} className="vie-taller"
          >
            <Translation id="never-share-blood" />
          </IconRow>
          <IconRow
            image={data.dontShareNeedles}
            alt={t("never-share-needles-alt")}
          >
            <Translation id="never-share-needles" />
          </IconRow>
          <IconRow image={data.avoidDonating} alt={t("avoid-donating")}>
            <Translation id="avoid-donating" />
          </IconRow>
          <IconRow image={data.useCondoms} alt={t("use-condoms")}>
            <Translation id="use-condoms" />
          </IconRow>
        </div>
        <div className="w-full laptop:w-1/2 laptop:pr-8">
          <IconRow image={data.coverCuts} alt={t("cover-cuts")}>
            <Translation id="cover-cuts" />
          </IconRow>
          <IconRow image={data.clean} alt={t("clean-blood-with-bleach")}>
            <Translation id="clean-blood-with-bleach" />
          </IconRow>
          <IconRow image={data.family} alt={t("encourage-family-alt")}>
            <Translation id="encourage-family" />
          </IconRow>
        </div>
      </div>
    </div>
  );
};

export default Key3Panel;
