import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Translation, { useTranslation } from "../../../Translation";
import MythPanel from "./MythPanel";

const Myth6Panel: FunctionComponent = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      expense: file(base: { eq: "expense.png" }) {
        ...SmallImage
      }
    }
  `);
  return (
    <MythPanel icon={data.expense} alt={t("myth-financial-alt")}>
      <Translation id="financial-programs" />
    </MythPanel>
  );
};

export default Myth6Panel;
