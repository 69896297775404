import React, { FunctionComponent } from "react";
import Translation, { useTranslation } from "../../../Translation";
import Image from "../../../Image";
import { graphql, useStaticQuery } from "gatsby";
const Key1Panel: FunctionComponent = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(
    graphql`
      query {
        apple: file(base: { eq: "apple.png" }) {
          ...SmallImage
        }
        noAlcohol: file(base: { eq: "no-alcohol.png" }) {
          ...SmallImage
        }
        medication: file(base: { eq: "medication.png" }) {
          ...SmallImage
        }
        noSmoking: file(base: { eq: "no-smoking.png" }) {
          ...SmallImage
        }
      }
    `
  );

  function item(image: any, text: JSX.Element, alt: string) {
    return (
      <div className="flex items-center w-full laptop:w-1/2 laptop:pr-8">
        <div className="w-10 mx-2 laptop:w-1/4">
          <Image fluid={image.childImageSharp.fluid} />
        </div>
        <div className="ml-4 w-3/4">
          <p>{text}</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="hidden laptop:block text-center font-bold text-2xl pt-4 pb-12">
        <Translation id="take-care-of-body" />
      </div>
      <div className="flex flex-wrap">
        {item(data.apple, <Translation id="healthy-diet" />, t("healthy-diet"))}
        {item(
          data.noAlcohol,
          <Translation id="avoid-alcohol" />,
          t("avoid-alcohol-alt")
        )}
        {item(
          data.medication,
          <Translation id="tell-doctor" />,
          t("tell-doctor-alt")
        )}
        {item(
          data.noSmoking,
          <Translation id="avoid-smoking" />,
          t("avoid-smoking")
        )}
      </div>
    </div>
  );
};

export default Key1Panel;
