import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Video from "../../Video";

const WhatShouldIKnow: FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      poster: file(base: { eq: "what-should-i-know.png" }) {
        publicURL
      }
      videos: allFile(filter: { base: { eq: "What_should_I_know.mp4" } }) {
        nodes {
          ...VideoQuery
        }
      }
    }
  `);

  return <Video videos={data.videos} poster={data.poster.publicURL} />;
};

export default WhatShouldIKnow;
