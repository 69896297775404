import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Translation from "../../../Translation";
import KeysTab from "./KeysTab";

const Key5Tab: FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      inactiveIcon: file(base: { eq: "five-inactive.png" }) {
        ...SmallImage
      }
      activeIcon: file(base: { eq: "five-active.png" }) {
        ...SmallImage
      }
    }
  `);

  return (
    <KeysTab {...data}>
      <Translation id="talk-openly-with-doctor" />
    </KeysTab>
  );
};

export default Key5Tab;
