import React, { FunctionComponent } from "react";
import Translation from "../../../Translation";
import MythsTab from "./MythsTab";

const Myth3Tab: FunctionComponent = () => {
  return (
    <MythsTab>
      <Translation id="myth-family" />
    </MythsTab>
  );
};

export default Myth3Tab;
