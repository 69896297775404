import React, { FunctionComponent } from "react";
import Image from "../../../Image";
import Translation, { useTranslation } from "../../../Translation";

type HbvDnaPanel = {
  icon: any;
  caution: any;
};
const HbvDnaPanel: FunctionComponent<HbvDnaPanel> = ({ icon, caution }) => {
  const { t } = useTranslation();
  return (
    <div className="pr-4 laptop:px-4">
      <div className="flex w-full">
        <div className="hidden laptop:block laptop:w-1/4">
          <Image
            fluid={icon.childImageSharp.fluid}
            alt={t("hbv-dna-alt")}
            className="mt-4"
          />
        </div>
        <div className="w-full laptop:w-3/4">
          <p>
            <Translation id="hbv-dna-measures" />
          </p>
          <p>
            <Translation id="if-viral-load-above" />
          </p>
          <p>
            <Translation id="viral-load-should-be" />
          </p>
          <p className="text-red font-bold">
            <Translation id="being-undetectable-does-not-mean" />
          </p>
        </div>
      </div>
      <div className="flex w-full">
        <div className="w-1/4 mx-auto">
          <Image
            fluid={caution.childImageSharp.fluid}
            alt={t("warning-symbol")}
            className="mt-4 mr-4"
          />
        </div>
        <div className="w-3/4">
          <p className="text-red font-bold">
            <Translation id="viral-load-and-alt-can-change" />
          </p>
          <p>
            <Translation id="important-to-test-regularly" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default HbvDnaPanel;
