import React, { FunctionComponent } from "react";
import Translation from "../../../Translation";
import MythsTab from "./MythsTab";

const Myth4Tab: FunctionComponent = () => {
  return (
    <MythsTab>
      <Translation id="myth-vaccine" />
    </MythsTab>
  );
};

export default Myth4Tab;
