import React, { FunctionComponent } from "react";
import { WildcardRouteProps } from "../../types";
import Layout from "../components/Layout/Layout";
import LivingWithHepB from "../components/Markup/LivingWithHepB/LivingWithHepB";
import SEO from "../components/seo";

const childRoutes = ["ongoing-monitoring", "managing-hep-b", "hep-b-facts"];

const LivingWithHepBPage: FunctionComponent<WildcardRouteProps> = ({
  "*": slug,
}) => {
  return (
    <Layout slug={slug} childRoutes={childRoutes}>
      <SEO name={slug || "living-with-hep-b"} baseName="living-with-hep-b" />
      <LivingWithHepB />
    </Layout>
  );
};
export default LivingWithHepBPage;
