import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Translation, { useTranslation } from "../../../Translation";
import MythPanel from "./MythPanel";

const Myth1Panel: FunctionComponent = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      virus: file(base: { eq: "virus.png" }) {
        ...SmallImage
      }
    }
  `);
  return (
    <MythPanel icon={data.virus} alt={t("myth-feel-fine-alt")}>
      <Translation id="may-feel-fine" />
    </MythPanel>
  );
};

export default Myth1Panel;
