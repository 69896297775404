import React, { FunctionComponent } from "react";
import Image from "./Image";

type IconRowProps = {
  image: any;
  alt?: string;
  className?: string;
};
const IconRow: FunctionComponent<IconRowProps> = ({ image, alt, children, className }) => {
  return (
    <div className={["flex items-center w-full laptop:pr-8", className].join(" ")}>
      <div className="w-10 mx-2 laptop:w-1/4 self-start laptop:self-center ">
        <Image fluid={image.childImageSharp.fluid} alt={alt} className="icon-row-icon" />
      </div>
      <div className="ml-4 w-3/4 font-normal">
        <p className="pb-4">{children}</p>
      </div>
    </div>
  );
};

export default IconRow;
