import React, { FunctionComponent } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Translation from "../../../Translation";
import MonitoringTab from "./MonitoringTab";

const AltTab: FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      infectedLiver: file(base: { eq: "infected-liver.png" }) {
        ...SmallImage
      }
    }
  `);

  return (
    <MonitoringTab icon={data.infectedLiver}>
      <Translation id="alt-test" />
    </MonitoringTab>
  );
};

export default AltTab;
