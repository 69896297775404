import React, { FunctionComponent } from "react";
import Image from "../../../Image";
import Translation, { useTranslation } from "../../../Translation";

type CancerPanelProps = {
  icon: any;
  caution: any;
};

const CancerPanel: FunctionComponent<CancerPanelProps> = ({
  icon,
  caution,
}) => {
  const { t } = useTranslation();
  return (
    <div className="pr-4 laptop:px-4">
      <div className="flex w-full">
        <div className="hidden laptop:block laptop:w-1/4">
          <Image
            fluid={icon.childImageSharp.fluid}
            alt={t("liver-cancer-screening-alt")}
            className="mt-4"
          />
        </div>
        <div className="w-full laptop:w-3/4">
          <p className="strong-red">
            <Translation id="afp-test-screens-for-cancer" />
          </p>
          <p className="strong-red">
            <Translation id="liver-ultrasound" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default CancerPanel;
