import React, { FunctionComponent } from "react";
import Translation from "../../../Translation";
import MythsTab from "./MythsTab";

const Myth2Tab: FunctionComponent = () => {
  return (
    <MythsTab>
      <Translation id="myth-carrier" />
    </MythsTab>
  );
};

export default Myth2Tab;
